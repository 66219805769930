/* eslint-disable */ // Auto-generated file (DO NOT EDIT!!!), refer gql-codegen.yml

import { offlineExchange } from '@urql/exchange-graphcache';
import { Resolver as GraphCacheResolver, UpdateResolver as GraphCacheUpdateResolver, OptimisticMutationResolver as GraphCacheOptimisticMutationResolver } from '@urql/exchange-graphcache';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type CollectionReorderData = {
  __typename?: 'CollectionReorderData';
  /** Team Collection being moved */
  collection: TeamCollection;
  /** Team Collection succeeding the collection being moved in its new position */
  nextCollection?: Maybe<TeamCollection>;
};

export type CreateTeamRequestInput = {
  /** JSON string representing the request data */
  request: Scalars['String']['input'];
  /** ID of the team the collection belongs to */
  teamID: Scalars['ID']['input'];
  /** Displayed title of the request */
  title: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept an Invitation */
  acceptTeamInvitation: TeamMember;
  /**
   * Adds a team member to the team via email
   * @deprecated This is only present for backwards compatibility and will be removed soon use team invitations instead
   */
  addTeamMemberByEmail: TeamMember;
  /** Create a collection that has a parent collection */
  createChildCollection: TeamCollection;
  /** Create a duplicate of an existing environment */
  createDuplicateEnvironment: TeamEnvironment;
  /** Create a request in the given collection. */
  createRequestInCollection: TeamRequest;
  /** Creates a collection at the root of the team hierarchy (no parent collection) */
  createRootCollection: TeamCollection;
  /** Create a shortcode for the given request. */
  createShortcode: Shortcode;
  /** Creates a team owned by the executing user */
  createTeam: Team;
  /** Create a new Team Environment for given Team ID */
  createTeamEnvironment: TeamEnvironment;
  /** Creates a Team Invitation */
  createTeamInvitation: TeamInvitation;
  /** Delete all variables from a Team Environment */
  deleteAllVariablesFromTeamEnvironment: TeamEnvironment;
  /** Delete a collection */
  deleteCollection: Scalars['Boolean']['output'];
  /** Delete a request with the given ID */
  deleteRequest: Scalars['Boolean']['output'];
  /** Deletes the team */
  deleteTeam: Scalars['Boolean']['output'];
  /** Delete a Team Environment for given Team ID */
  deleteTeamEnvironment: Scalars['Boolean']['output'];
  /** Delete an user account */
  deleteUser: Scalars['Boolean']['output'];
  /** Duplicate a Team Collection */
  duplicateTeamCollection: Scalars['Boolean']['output'];
  /** Import collection from user firestore */
  importCollectionFromUserFirestore: TeamCollection;
  /** Import collections from JSON string to the specified Team */
  importCollectionsFromJSON: Scalars['Boolean']['output'];
  /** Leaves a team the executing user is a part of */
  leaveTeam: Scalars['Boolean']['output'];
  /** Move a collection into a new parent collection or the root of the team */
  moveCollection: TeamCollection;
  /** Move a request to the given collection */
  moveRequest: TeamRequest;
  /** Removes the team member from the team */
  removeTeamMember: Scalars['Boolean']['output'];
  /**
   * Rename a collection
   * @deprecated Deprecated use `updateTeamCollection` mutation instead
   */
  renameCollection: TeamCollection;
  /** Renames a team */
  renameTeam: Team;
  /** Replace existing collections of a specific team with collections in JSON string */
  replaceCollectionsWithJSON: Scalars['Boolean']['output'];
  /** Revoke a user generated shortcode */
  revokeShortcode: Scalars['Boolean']['output'];
  /** Revokes an invitation and deletes it */
  revokeTeamInvitation: Scalars['Boolean']['output'];
  /** Update the order of collections */
  updateCollectionOrder: Scalars['Boolean']['output'];
  /** Update a user generated Shortcode */
  updateEmbedProperties: Shortcode;
  /** Update the order of requests in the lookup table */
  updateLookUpRequestOrder: Scalars['Boolean']['output'];
  /** Update a request with the given ID */
  updateRequest: TeamRequest;
  /** Update Team Collection details */
  updateTeamCollection: TeamCollection;
  /** Add/Edit a single environment variable or variables to a Team Environment */
  updateTeamEnvironment: TeamEnvironment;
  /** Update role of a team member the executing user owns */
  updateTeamMemberRole: TeamMember;
};


export type MutationAcceptTeamInvitationArgs = {
  inviteID: Scalars['ID']['input'];
};


export type MutationAddTeamMemberByEmailArgs = {
  teamID: Scalars['ID']['input'];
  userEmail: Scalars['String']['input'];
  userRole: TeamMemberRole;
};


export type MutationCreateChildCollectionArgs = {
  childTitle: Scalars['String']['input'];
  collectionID: Scalars['ID']['input'];
  data?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateDuplicateEnvironmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateRequestInCollectionArgs = {
  collectionID: Scalars['ID']['input'];
  data: CreateTeamRequestInput;
};


export type MutationCreateRootCollectionArgs = {
  data?: InputMaybe<Scalars['String']['input']>;
  teamID: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};


export type MutationCreateShortcodeArgs = {
  properties?: InputMaybe<Scalars['String']['input']>;
  request: Scalars['String']['input'];
};


export type MutationCreateTeamArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateTeamEnvironmentArgs = {
  name: Scalars['String']['input'];
  teamID: Scalars['ID']['input'];
  variables: Scalars['String']['input'];
};


export type MutationCreateTeamInvitationArgs = {
  inviteeEmail: Scalars['String']['input'];
  inviteeRole: TeamMemberRole;
  teamID: Scalars['ID']['input'];
};


export type MutationDeleteAllVariablesFromTeamEnvironmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCollectionArgs = {
  collectionID: Scalars['ID']['input'];
};


export type MutationDeleteRequestArgs = {
  requestID: Scalars['ID']['input'];
};


export type MutationDeleteTeamArgs = {
  teamID: Scalars['ID']['input'];
};


export type MutationDeleteTeamEnvironmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDuplicateTeamCollectionArgs = {
  collectionID: Scalars['String']['input'];
};


export type MutationImportCollectionFromUserFirestoreArgs = {
  fbCollectionPath: Scalars['String']['input'];
  parentCollectionID?: InputMaybe<Scalars['ID']['input']>;
  teamID: Scalars['ID']['input'];
};


export type MutationImportCollectionsFromJsonArgs = {
  jsonString: Scalars['String']['input'];
  parentCollectionID?: InputMaybe<Scalars['ID']['input']>;
  teamID: Scalars['ID']['input'];
};


export type MutationLeaveTeamArgs = {
  teamID: Scalars['ID']['input'];
};


export type MutationMoveCollectionArgs = {
  collectionID: Scalars['ID']['input'];
  parentCollectionID?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationMoveRequestArgs = {
  destCollID: Scalars['ID']['input'];
  requestID: Scalars['ID']['input'];
};


export type MutationRemoveTeamMemberArgs = {
  teamID: Scalars['ID']['input'];
  userUid: Scalars['ID']['input'];
};


export type MutationRenameCollectionArgs = {
  collectionID: Scalars['ID']['input'];
  newTitle: Scalars['String']['input'];
};


export type MutationRenameTeamArgs = {
  newName: Scalars['String']['input'];
  teamID: Scalars['ID']['input'];
};


export type MutationReplaceCollectionsWithJsonArgs = {
  jsonString: Scalars['String']['input'];
  parentCollectionID?: InputMaybe<Scalars['ID']['input']>;
  teamID: Scalars['ID']['input'];
};


export type MutationRevokeShortcodeArgs = {
  code: Scalars['ID']['input'];
};


export type MutationRevokeTeamInvitationArgs = {
  inviteID: Scalars['ID']['input'];
};


export type MutationUpdateCollectionOrderArgs = {
  collectionID: Scalars['ID']['input'];
  destCollID?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdateEmbedPropertiesArgs = {
  code: Scalars['ID']['input'];
  properties: Scalars['String']['input'];
};


export type MutationUpdateLookUpRequestOrderArgs = {
  collectionID: Scalars['ID']['input'];
  nextRequestID?: InputMaybe<Scalars['ID']['input']>;
  requestID: Scalars['ID']['input'];
};


export type MutationUpdateRequestArgs = {
  data: UpdateTeamRequestInput;
  requestID: Scalars['ID']['input'];
};


export type MutationUpdateTeamCollectionArgs = {
  collectionID: Scalars['ID']['input'];
  data?: InputMaybe<Scalars['String']['input']>;
  newTitle?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateTeamEnvironmentArgs = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  variables: Scalars['String']['input'];
};


export type MutationUpdateTeamMemberRoleArgs = {
  newRole: TeamMemberRole;
  teamID: Scalars['ID']['input'];
  userUid: Scalars['ID']['input'];
};

export type Query = {
  __typename?: 'Query';
  /** Get a collection with the given ID or null (if not exists) */
  collection?: Maybe<TeamCollection>;
  /**
   * Returns the collections of the team
   * @deprecated Deprecated because of no practical use. Use `rootCollectionsOfTeam` instead.
   */
  collectionsOfTeam: Array<TeamCollection>;
  /** Returns the JSON string giving the collections and their contents of the team */
  exportCollectionsToJSON: Scalars['String']['output'];
  /** Gives details of the user executing this query (pass Authorization 'Bearer' header) */
  me: User;
  /** List all shortcodes the current user has generated */
  myShortcodes: Array<Shortcode>;
  /** List of teams that the executing user belongs to. */
  myTeams: Array<Team>;
  /** Gives a request with the given ID or null (if not exists) */
  request?: Maybe<TeamRequest>;
  /** Gives a list of requests in the collection */
  requestsInCollection: Array<TeamRequest>;
  /** Returns the collections of the team */
  rootCollectionsOfTeam: Array<TeamCollection>;
  /** Search the team for a specific request with title */
  searchForRequest: Array<TeamRequest>;
  /** Resolves and returns a shortcode data */
  shortcode?: Maybe<Shortcode>;
  /** Returns the detail of the team with the given ID */
  team?: Maybe<Team>;
  /** Gets the Team Invitation with the given ID, or null if not exists */
  teamInvitation: TeamInvitation;
  /**
   * Finds a user by their UID or null if no match
   * @deprecated Deprecated due to privacy concerns. Try to get the user from the context-relevant queries
   */
  user?: Maybe<User>;
};


export type QueryCollectionArgs = {
  collectionID: Scalars['ID']['input'];
};


export type QueryCollectionsOfTeamArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  teamID: Scalars['ID']['input'];
};


export type QueryExportCollectionsToJsonArgs = {
  teamID: Scalars['ID']['input'];
};


export type QueryMyShortcodesArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryMyTeamsArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryRequestArgs = {
  requestID: Scalars['ID']['input'];
};


export type QueryRequestsInCollectionArgs = {
  collectionID: Scalars['ID']['input'];
  cursor?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryRootCollectionsOfTeamArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  teamID: Scalars['ID']['input'];
};


export type QuerySearchForRequestArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  searchTerm: Scalars['String']['input'];
  teamID: Scalars['ID']['input'];
};


export type QueryShortcodeArgs = {
  code: Scalars['ID']['input'];
};


export type QueryTeamArgs = {
  teamID: Scalars['ID']['input'];
};


export type QueryTeamInvitationArgs = {
  inviteID: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  uid: Scalars['ID']['input'];
};

export type RequestReorderData = {
  __typename?: 'RequestReorderData';
  /** Team Request succeeding the request being moved in its new position */
  nextRequest?: Maybe<TeamRequest>;
  /** Team Request being moved */
  request: TeamRequest;
};

export type Shortcode = {
  __typename?: 'Shortcode';
  /** Timestamp of when the Shortcode was created */
  createdOn: Scalars['DateTime']['output'];
  /** The 12 digit alphanumeric code */
  id: Scalars['ID']['output'];
  /** JSON string representing the properties for an embed */
  properties?: Maybe<Scalars['String']['output']>;
  /** JSON string representing the request data */
  request: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Listen to when a collections position has changed */
  collectionOrderUpdated: CollectionReorderData;
  /** Listen for shortcode creation */
  myShortcodesCreated: Shortcode;
  /** Listen for shortcode deletion */
  myShortcodesRevoked: Shortcode;
  /** Listen for Shortcode updates */
  myShortcodesUpdated: Shortcode;
  /** Emitted when a request has been moved from one collection into another */
  requestMoved: TeamRequest;
  /** Emitted when a requests position has been changed in its collection */
  requestOrderUpdated: RequestReorderData;
  /** Listen to when a collection has been added to a team. The emitted value is the team added */
  teamCollectionAdded: TeamCollection;
  /** Listen to when a collection has been moved */
  teamCollectionMoved: TeamCollection;
  /** Listen to when a collection has been removed */
  teamCollectionRemoved: Scalars['ID']['output'];
  /** Listen to when a collection has been updated. */
  teamCollectionUpdated: TeamCollection;
  /** Listen for Team Environment Creation Messages */
  teamEnvironmentCreated: TeamEnvironment;
  /** Listen for Team Environment Deletion Messages */
  teamEnvironmentDeleted: TeamEnvironment;
  /** Listen for Team Environment Updates */
  teamEnvironmentUpdated: TeamEnvironment;
  /** Listens to when a Team Invitation is added */
  teamInvitationAdded: TeamInvitation;
  /** Listens to when a Team Invitation is removed */
  teamInvitationRemoved: Scalars['ID']['output'];
  /** Listen to when a new team member being added to the team. The emitted value is the new team member added. */
  teamMemberAdded: TeamMember;
  /** Listen to when a team member has been removed. The emitted value is the uid of the user removed */
  teamMemberRemoved: Scalars['ID']['output'];
  /** Listen to when a team member status has been updated. The emitted value is the new team member status */
  teamMemberUpdated: TeamMember;
  /** Emits when a new request is added to a team */
  teamRequestAdded: TeamRequest;
  /** Emitted when a request has been deleted. Only the id of the request is emitted. */
  teamRequestDeleted: Scalars['ID']['output'];
  /** Emitted when a request has been updated */
  teamRequestUpdated: TeamRequest;
  /** Listen for user deletion */
  userDeleted: User;
};


export type SubscriptionCollectionOrderUpdatedArgs = {
  teamID: Scalars['ID']['input'];
};


export type SubscriptionRequestMovedArgs = {
  teamID: Scalars['ID']['input'];
};


export type SubscriptionRequestOrderUpdatedArgs = {
  teamID: Scalars['ID']['input'];
};


export type SubscriptionTeamCollectionAddedArgs = {
  teamID: Scalars['ID']['input'];
};


export type SubscriptionTeamCollectionMovedArgs = {
  teamID: Scalars['ID']['input'];
};


export type SubscriptionTeamCollectionRemovedArgs = {
  teamID: Scalars['ID']['input'];
};


export type SubscriptionTeamCollectionUpdatedArgs = {
  teamID: Scalars['ID']['input'];
};


export type SubscriptionTeamEnvironmentCreatedArgs = {
  teamID: Scalars['ID']['input'];
};


export type SubscriptionTeamEnvironmentDeletedArgs = {
  teamID: Scalars['ID']['input'];
};


export type SubscriptionTeamEnvironmentUpdatedArgs = {
  teamID: Scalars['ID']['input'];
};


export type SubscriptionTeamInvitationAddedArgs = {
  teamID: Scalars['ID']['input'];
};


export type SubscriptionTeamInvitationRemovedArgs = {
  teamID: Scalars['ID']['input'];
};


export type SubscriptionTeamMemberAddedArgs = {
  teamID: Scalars['ID']['input'];
};


export type SubscriptionTeamMemberRemovedArgs = {
  teamID: Scalars['ID']['input'];
};


export type SubscriptionTeamMemberUpdatedArgs = {
  teamID: Scalars['ID']['input'];
};


export type SubscriptionTeamRequestAddedArgs = {
  teamID: Scalars['ID']['input'];
};


export type SubscriptionTeamRequestDeletedArgs = {
  teamID: Scalars['ID']['input'];
};


export type SubscriptionTeamRequestUpdatedArgs = {
  teamID: Scalars['ID']['input'];
};

export type Team = {
  __typename?: 'Team';
  /** The number of users with the EDITOR role in the team */
  editorsCount: Scalars['Int']['output'];
  /** ID of the team */
  id: Scalars['ID']['output'];
  /** Returns the list of members of a team */
  members: Array<TeamMember>;
  /** The role of the current user in the team */
  myRole: TeamMemberRole;
  /** Displayed name of the team */
  name: Scalars['String']['output'];
  /** The number of users with the OWNER role in the team */
  ownersCount: Scalars['Int']['output'];
  /** Returns all Team Environments for the given Team */
  teamEnvironments: Array<TeamEnvironment>;
  /** Get all the active invites in the team */
  teamInvitations: Array<TeamInvitation>;
  /** Returns the list of members of a team */
  teamMembers: Array<TeamMember>;
  /** The number of users with the VIEWER role in the team */
  viewersCount: Scalars['Int']['output'];
};


export type TeamMembersArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
};

export type TeamCollection = {
  __typename?: 'TeamCollection';
  /** List of children collection */
  children: Array<TeamCollection>;
  /** JSON string representing the collection data */
  data?: Maybe<Scalars['String']['output']>;
  /** ID of the collection */
  id: Scalars['ID']['output'];
  /** The collection whom is the parent of this collection (null if this is root collection) */
  parent?: Maybe<TeamCollection>;
  /** Team the collection belongs to */
  team: Team;
  /** Displayed title of the collection */
  title: Scalars['String']['output'];
};


export type TeamCollectionChildrenArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
};

export type TeamEnvironment = {
  __typename?: 'TeamEnvironment';
  /** ID of the Team Environment */
  id: Scalars['ID']['output'];
  /** Name of the environment */
  name: Scalars['String']['output'];
  /** ID of the team this environment belongs to */
  teamID: Scalars['ID']['output'];
  /** All variables present in the environment */
  variables: Scalars['String']['output'];
};

export type TeamInvitation = {
  __typename?: 'TeamInvitation';
  /** Get the creator of the invite */
  creator: User;
  /** UID of the creator of the invite */
  creatorUid: Scalars['ID']['output'];
  /** ID of the invite */
  id: Scalars['ID']['output'];
  /** Email of the invitee */
  inviteeEmail: Scalars['ID']['output'];
  /** The role that will be given to the invitee */
  inviteeRole: TeamMemberRole;
  /** Get the team associated to the invite */
  team: Team;
  /** ID of the team the invite is to */
  teamID: Scalars['ID']['output'];
};

export type TeamMember = {
  __typename?: 'TeamMember';
  /** Membership ID of the Team Member */
  membershipID: Scalars['ID']['output'];
  /** Role of the given team member in the given team */
  role: TeamMemberRole;
  user: User;
};

export enum TeamMemberRole {
  Editor = 'EDITOR',
  Owner = 'OWNER',
  Viewer = 'VIEWER'
}

export type TeamRequest = {
  __typename?: 'TeamRequest';
  /** Collection the request belongs to */
  collection: TeamCollection;
  /** ID of the collection the request belongs to. */
  collectionID: Scalars['ID']['output'];
  /** ID of the request */
  id: Scalars['ID']['output'];
  /** JSON string representing the request data */
  request: Scalars['String']['output'];
  /** Team the request belongs to */
  team: Team;
  /** ID of the team the request belongs to. */
  teamID: Scalars['ID']['output'];
  /** Displayed title of the request */
  title: Scalars['String']['output'];
};

export type UpdateTeamRequestInput = {
  /** JSON string representing the request data */
  request?: InputMaybe<Scalars['String']['input']>;
  /** Displayed title of the request */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  /** Displayed name of the user (if given) */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Email of the user (if given) */
  email?: Maybe<Scalars['String']['output']>;
  /** URL to the profile photo of the user (if given) */
  photoURL?: Maybe<Scalars['String']['output']>;
  /** Firebase UID of the user */
  uid: Scalars['ID']['output'];
};

export type AcceptTeamInvitationMutationVariables = Exact<{
  inviteID: Scalars['ID']['input'];
}>;


export type AcceptTeamInvitationMutation = { __typename?: 'Mutation', acceptTeamInvitation: { __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole, user: { __typename?: 'User', uid: string, displayName?: string | null, photoURL?: string | null, email?: string | null } } };

export type CreateChildCollectionMutationVariables = Exact<{
  childTitle: Scalars['String']['input'];
  collectionID: Scalars['ID']['input'];
}>;


export type CreateChildCollectionMutation = { __typename?: 'Mutation', createChildCollection: { __typename?: 'TeamCollection', id: string } };

export type CreateDuplicateEnvironmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CreateDuplicateEnvironmentMutation = { __typename?: 'Mutation', createDuplicateEnvironment: { __typename?: 'TeamEnvironment', id: string, teamID: string, name: string, variables: string } };

export type CreateNewRootCollectionMutationVariables = Exact<{
  title: Scalars['String']['input'];
  teamID: Scalars['ID']['input'];
}>;


export type CreateNewRootCollectionMutation = { __typename?: 'Mutation', createRootCollection: { __typename?: 'TeamCollection', id: string } };

export type CreateRequestInCollectionMutationVariables = Exact<{
  data: CreateTeamRequestInput;
  collectionID: Scalars['ID']['input'];
}>;


export type CreateRequestInCollectionMutation = { __typename?: 'Mutation', createRequestInCollection: { __typename?: 'TeamRequest', id: string, collection: { __typename?: 'TeamCollection', id: string, team: { __typename?: 'Team', id: string, name: string } } } };

export type CreateShortcodeMutationVariables = Exact<{
  request: Scalars['String']['input'];
  properties?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateShortcodeMutation = { __typename?: 'Mutation', createShortcode: { __typename?: 'Shortcode', id: string, request: string, createdOn: any, properties?: string | null } };

export type CreateTeamMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type CreateTeamMutation = { __typename?: 'Mutation', createTeam: { __typename?: 'Team', id: string, name: string, myRole: TeamMemberRole, ownersCount: number, editorsCount: number, viewersCount: number, members: Array<{ __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole, user: { __typename?: 'User', uid: string, displayName?: string | null, email?: string | null, photoURL?: string | null } }> } };

export type CreateTeamEnvironmentMutationVariables = Exact<{
  variables: Scalars['String']['input'];
  teamID: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;


export type CreateTeamEnvironmentMutation = { __typename?: 'Mutation', createTeamEnvironment: { __typename?: 'TeamEnvironment', variables: string, name: string, teamID: string, id: string } };

export type CreateTeamInvitationMutationVariables = Exact<{
  inviteeEmail: Scalars['String']['input'];
  inviteeRole: TeamMemberRole;
  teamID: Scalars['ID']['input'];
}>;


export type CreateTeamInvitationMutation = { __typename?: 'Mutation', createTeamInvitation: { __typename?: 'TeamInvitation', id: string, teamID: string, creatorUid: string, inviteeEmail: string, inviteeRole: TeamMemberRole } };

export type DeleteCollectionMutationVariables = Exact<{
  collectionID: Scalars['ID']['input'];
}>;


export type DeleteCollectionMutation = { __typename?: 'Mutation', deleteCollection: boolean };

export type DeleteRequestMutationVariables = Exact<{
  requestID: Scalars['ID']['input'];
}>;


export type DeleteRequestMutation = { __typename?: 'Mutation', deleteRequest: boolean };

export type DeleteShortcodeMutationVariables = Exact<{
  code: Scalars['ID']['input'];
}>;


export type DeleteShortcodeMutation = { __typename?: 'Mutation', revokeShortcode: boolean };

export type DeleteTeamMutationVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type DeleteTeamMutation = { __typename?: 'Mutation', deleteTeam: boolean };

export type DeleteTeamEnvironmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteTeamEnvironmentMutation = { __typename?: 'Mutation', deleteTeamEnvironment: boolean };

export type DeleteUserMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type DuplicateTeamCollectionMutationVariables = Exact<{
  collectionID: Scalars['String']['input'];
}>;


export type DuplicateTeamCollectionMutation = { __typename?: 'Mutation', duplicateTeamCollection: boolean };

export type ImportFromJsonMutationVariables = Exact<{
  jsonString: Scalars['String']['input'];
  teamID: Scalars['ID']['input'];
}>;


export type ImportFromJsonMutation = { __typename?: 'Mutation', importCollectionsFromJSON: boolean };

export type LeaveTeamMutationVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type LeaveTeamMutation = { __typename?: 'Mutation', leaveTeam: boolean };

export type MoveRestTeamCollectionMutationVariables = Exact<{
  collectionID: Scalars['ID']['input'];
  parentCollectionID?: InputMaybe<Scalars['ID']['input']>;
}>;


export type MoveRestTeamCollectionMutation = { __typename?: 'Mutation', moveCollection: { __typename?: 'TeamCollection', id: string } };

export type MoveRestTeamRequestMutationVariables = Exact<{
  collectionID: Scalars['ID']['input'];
  requestID: Scalars['ID']['input'];
}>;


export type MoveRestTeamRequestMutation = { __typename?: 'Mutation', moveRequest: { __typename?: 'TeamRequest', id: string } };

export type RemoveTeamMemberMutationVariables = Exact<{
  userUid: Scalars['ID']['input'];
  teamID: Scalars['ID']['input'];
}>;


export type RemoveTeamMemberMutation = { __typename?: 'Mutation', removeTeamMember: boolean };

export type RenameCollectionMutationVariables = Exact<{
  newTitle: Scalars['String']['input'];
  collectionID: Scalars['ID']['input'];
}>;


export type RenameCollectionMutation = { __typename?: 'Mutation', renameCollection: { __typename?: 'TeamCollection', id: string } };

export type RenameTeamMutationVariables = Exact<{
  newName: Scalars['String']['input'];
  teamID: Scalars['ID']['input'];
}>;


export type RenameTeamMutation = { __typename?: 'Mutation', renameTeam: { __typename?: 'Team', id: string, name: string, teamMembers: Array<{ __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole, user: { __typename?: 'User', uid: string } }> } };

export type RevokeTeamInvitationMutationVariables = Exact<{
  inviteID: Scalars['ID']['input'];
}>;


export type RevokeTeamInvitationMutation = { __typename?: 'Mutation', revokeTeamInvitation: boolean };

export type UpdateCollectionOrderMutationVariables = Exact<{
  collectionID: Scalars['ID']['input'];
  destCollID?: InputMaybe<Scalars['ID']['input']>;
}>;


export type UpdateCollectionOrderMutation = { __typename?: 'Mutation', updateCollectionOrder: boolean };

export type UpdateEmbedPropertiesMutationVariables = Exact<{
  code: Scalars['ID']['input'];
  properties: Scalars['String']['input'];
}>;


export type UpdateEmbedPropertiesMutation = { __typename?: 'Mutation', updateEmbedProperties: { __typename?: 'Shortcode', id: string, request: string, properties?: string | null, createdOn: any } };

export type UpdateLookUpRequestOrderMutationVariables = Exact<{
  requestID: Scalars['ID']['input'];
  nextRequestID?: InputMaybe<Scalars['ID']['input']>;
  collectionID: Scalars['ID']['input'];
}>;


export type UpdateLookUpRequestOrderMutation = { __typename?: 'Mutation', updateLookUpRequestOrder: boolean };

export type UpdateRequestMutationVariables = Exact<{
  data: UpdateTeamRequestInput;
  requestID: Scalars['ID']['input'];
}>;


export type UpdateRequestMutation = { __typename?: 'Mutation', updateRequest: { __typename?: 'TeamRequest', id: string, title: string } };

export type UpdateTeamCollectionMutationVariables = Exact<{
  collectionID: Scalars['ID']['input'];
  newTitle?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateTeamCollectionMutation = { __typename?: 'Mutation', updateTeamCollection: { __typename?: 'TeamCollection', id: string, title: string, data?: string | null } };

export type UpdateTeamEnvironmentMutationVariables = Exact<{
  variables: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;


export type UpdateTeamEnvironmentMutation = { __typename?: 'Mutation', updateTeamEnvironment: { __typename?: 'TeamEnvironment', variables: string, name: string, id: string } };

export type UpdateTeamMemberRoleMutationVariables = Exact<{
  newRole: TeamMemberRole;
  userUid: Scalars['ID']['input'];
  teamID: Scalars['ID']['input'];
}>;


export type UpdateTeamMemberRoleMutation = { __typename?: 'Mutation', updateTeamMemberRole: { __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole } };

export type ExportAsJsonQueryVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type ExportAsJsonQuery = { __typename?: 'Query', exportCollectionsToJSON: string };

export type GetCollectionChildrenQueryVariables = Exact<{
  collectionID: Scalars['ID']['input'];
  cursor?: InputMaybe<Scalars['ID']['input']>;
}>;


export type GetCollectionChildrenQuery = { __typename?: 'Query', collection?: { __typename?: 'TeamCollection', children: Array<{ __typename?: 'TeamCollection', id: string, title: string, data?: string | null }> } | null };

export type GetCollectionChildrenIDsQueryVariables = Exact<{
  collectionID: Scalars['ID']['input'];
  cursor?: InputMaybe<Scalars['ID']['input']>;
}>;


export type GetCollectionChildrenIDsQuery = { __typename?: 'Query', collection?: { __typename?: 'TeamCollection', children: Array<{ __typename?: 'TeamCollection', id: string }> } | null };

export type GetCollectionRequestsQueryVariables = Exact<{
  collectionID: Scalars['ID']['input'];
  cursor?: InputMaybe<Scalars['ID']['input']>;
}>;


export type GetCollectionRequestsQuery = { __typename?: 'Query', requestsInCollection: Array<{ __typename?: 'TeamRequest', id: string, title: string, request: string }> };

export type GetCollectionTitleAndDataQueryVariables = Exact<{
  collectionID: Scalars['ID']['input'];
}>;


export type GetCollectionTitleAndDataQuery = { __typename?: 'Query', collection?: { __typename?: 'TeamCollection', title: string, data?: string | null } | null };

export type GetInviteDetailsQueryVariables = Exact<{
  inviteID: Scalars['ID']['input'];
}>;


export type GetInviteDetailsQuery = { __typename?: 'Query', teamInvitation: { __typename?: 'TeamInvitation', id: string, inviteeEmail: string, inviteeRole: TeamMemberRole, team: { __typename?: 'Team', id: string, name: string }, creator: { __typename?: 'User', uid: string, displayName?: string | null, email?: string | null } } };

export type GetUserShortcodesQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['ID']['input']>;
}>;


export type GetUserShortcodesQuery = { __typename?: 'Query', myShortcodes: Array<{ __typename?: 'Shortcode', id: string, request: string, createdOn: any, properties?: string | null }> };

export type GetMyTeamsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['ID']['input']>;
}>;


export type GetMyTeamsQuery = { __typename?: 'Query', myTeams: Array<{ __typename?: 'Team', id: string, name: string, myRole: TeamMemberRole, ownersCount: number, teamMembers: Array<{ __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole, user: { __typename?: 'User', photoURL?: string | null, displayName?: string | null, email?: string | null, uid: string } }> }> };

export type GetSingleCollectionQueryVariables = Exact<{
  collectionID: Scalars['ID']['input'];
}>;


export type GetSingleCollectionQuery = { __typename?: 'Query', collection?: { __typename?: 'TeamCollection', id: string, title: string, data?: string | null, parent?: { __typename?: 'TeamCollection', id: string } | null } | null };

export type GetSingleRequestQueryVariables = Exact<{
  requestID: Scalars['ID']['input'];
}>;


export type GetSingleRequestQuery = { __typename?: 'Query', request?: { __typename?: 'TeamRequest', id: string, collectionID: string, title: string, request: string } | null };

export type GetTeamQueryVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type GetTeamQuery = { __typename?: 'Query', team?: { __typename?: 'Team', id: string, name: string, teamMembers: Array<{ __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole, user: { __typename?: 'User', uid: string, email?: string | null } }> } | null };

export type GetTeamEnvironmentsQueryVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type GetTeamEnvironmentsQuery = { __typename?: 'Query', team?: { __typename?: 'Team', teamEnvironments: Array<{ __typename?: 'TeamEnvironment', id: string, name: string, variables: string, teamID: string }> } | null };

export type GetTeamMembersQueryVariables = Exact<{
  teamID: Scalars['ID']['input'];
  cursor?: InputMaybe<Scalars['ID']['input']>;
}>;


export type GetTeamMembersQuery = { __typename?: 'Query', team?: { __typename?: 'Team', members: Array<{ __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole, user: { __typename?: 'User', uid: string, email?: string | null } }> } | null };

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserInfoQuery = { __typename?: 'Query', me: { __typename?: 'User', uid: string, displayName?: string | null, email?: string | null, photoURL?: string | null } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', uid: string, displayName?: string | null, photoURL?: string | null } };

export type ResolveShortcodeQueryVariables = Exact<{
  code: Scalars['ID']['input'];
}>;


export type ResolveShortcodeQuery = { __typename?: 'Query', shortcode?: { __typename?: 'Shortcode', id: string, request: string, properties?: string | null } | null };

export type RootCollectionsOfTeamQueryVariables = Exact<{
  teamID: Scalars['ID']['input'];
  cursor?: InputMaybe<Scalars['ID']['input']>;
}>;


export type RootCollectionsOfTeamQuery = { __typename?: 'Query', rootCollectionsOfTeam: Array<{ __typename?: 'TeamCollection', id: string, title: string, data?: string | null }> };

export type GetPendingInvitesQueryVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type GetPendingInvitesQuery = { __typename?: 'Query', team?: { __typename?: 'Team', id: string, teamInvitations: Array<{ __typename?: 'TeamInvitation', inviteeRole: TeamMemberRole, inviteeEmail: string, id: string }> } | null };

export type ShortcodeCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ShortcodeCreatedSubscription = { __typename?: 'Subscription', myShortcodesCreated: { __typename?: 'Shortcode', id: string, request: string, createdOn: any, properties?: string | null } };

export type ShortcodeDeletedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ShortcodeDeletedSubscription = { __typename?: 'Subscription', myShortcodesRevoked: { __typename?: 'Shortcode', id: string } };

export type ShortcodeUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ShortcodeUpdatedSubscription = { __typename?: 'Subscription', myShortcodesUpdated: { __typename?: 'Shortcode', id: string, request: string, createdOn: any, properties?: string | null } };

export type TeamCollectionAddedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamCollectionAddedSubscription = { __typename?: 'Subscription', teamCollectionAdded: { __typename?: 'TeamCollection', id: string, title: string, data?: string | null, parent?: { __typename?: 'TeamCollection', id: string } | null } };

export type TeamCollectionMovedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamCollectionMovedSubscription = { __typename?: 'Subscription', teamCollectionMoved: { __typename?: 'TeamCollection', id: string, title: string, parent?: { __typename?: 'TeamCollection', id: string } | null } };

export type TeamCollectionOrderUpdatedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamCollectionOrderUpdatedSubscription = { __typename?: 'Subscription', collectionOrderUpdated: { __typename?: 'CollectionReorderData', collection: { __typename?: 'TeamCollection', id: string, title: string, parent?: { __typename?: 'TeamCollection', id: string } | null }, nextCollection?: { __typename?: 'TeamCollection', id: string, title: string, parent?: { __typename?: 'TeamCollection', id: string } | null } | null } };

export type TeamCollectionRemovedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamCollectionRemovedSubscription = { __typename?: 'Subscription', teamCollectionRemoved: string };

export type TeamCollectionUpdatedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamCollectionUpdatedSubscription = { __typename?: 'Subscription', teamCollectionUpdated: { __typename?: 'TeamCollection', id: string, title: string, data?: string | null, parent?: { __typename?: 'TeamCollection', id: string } | null } };

export type TeamEnvironmentCreatedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamEnvironmentCreatedSubscription = { __typename?: 'Subscription', teamEnvironmentCreated: { __typename?: 'TeamEnvironment', id: string, teamID: string, name: string, variables: string } };

export type TeamEnvironmentDeletedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamEnvironmentDeletedSubscription = { __typename?: 'Subscription', teamEnvironmentDeleted: { __typename?: 'TeamEnvironment', id: string } };

export type TeamEnvironmentUpdatedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamEnvironmentUpdatedSubscription = { __typename?: 'Subscription', teamEnvironmentUpdated: { __typename?: 'TeamEnvironment', id: string, teamID: string, name: string, variables: string } };

export type TeamInvitationAddedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamInvitationAddedSubscription = { __typename?: 'Subscription', teamInvitationAdded: { __typename?: 'TeamInvitation', id: string } };

export type TeamInvitationRemovedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamInvitationRemovedSubscription = { __typename?: 'Subscription', teamInvitationRemoved: string };

export type TeamMemberAddedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamMemberAddedSubscription = { __typename?: 'Subscription', teamMemberAdded: { __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole, user: { __typename?: 'User', uid: string, email?: string | null } } };

export type TeamMemberRemovedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamMemberRemovedSubscription = { __typename?: 'Subscription', teamMemberRemoved: string };

export type TeamMemberUpdatedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamMemberUpdatedSubscription = { __typename?: 'Subscription', teamMemberUpdated: { __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole, user: { __typename?: 'User', uid: string, email?: string | null } } };

export type TeamRequestAddedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamRequestAddedSubscription = { __typename?: 'Subscription', teamRequestAdded: { __typename?: 'TeamRequest', id: string, collectionID: string, request: string, title: string } };

export type TeamRequestDeletedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamRequestDeletedSubscription = { __typename?: 'Subscription', teamRequestDeleted: string };

export type TeamRequestMovedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamRequestMovedSubscription = { __typename?: 'Subscription', requestMoved: { __typename?: 'TeamRequest', id: string, collectionID: string, request: string, title: string } };

export type TeamRequestOrderUpdatedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamRequestOrderUpdatedSubscription = { __typename?: 'Subscription', requestOrderUpdated: { __typename?: 'RequestReorderData', request: { __typename?: 'TeamRequest', id: string, collectionID: string, request: string, title: string }, nextRequest?: { __typename?: 'TeamRequest', id: string, collectionID: string, request: string, title: string } | null } };

export type TeamRequestUpdatedSubscriptionVariables = Exact<{
  teamID: Scalars['ID']['input'];
}>;


export type TeamRequestUpdatedSubscription = { __typename?: 'Subscription', teamRequestUpdated: { __typename?: 'TeamRequest', id: string, collectionID: string, request: string, title: string } };


export const AcceptTeamInvitationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AcceptTeamInvitation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inviteID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptTeamInvitation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"inviteID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inviteID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"role"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"photoURL"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]}}]} as unknown as DocumentNode<AcceptTeamInvitationMutation, AcceptTeamInvitationMutationVariables>;
export const CreateChildCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateChildCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"childTitle"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createChildCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"childTitle"},"value":{"kind":"Variable","name":{"kind":"Name","value":"childTitle"}}},{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateChildCollectionMutation, CreateChildCollectionMutationVariables>;
export const CreateDuplicateEnvironmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateDuplicateEnvironment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createDuplicateEnvironment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"teamID"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"variables"}}]}}]}}]} as unknown as DocumentNode<CreateDuplicateEnvironmentMutation, CreateDuplicateEnvironmentMutationVariables>;
export const CreateNewRootCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateNewRootCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"title"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createRootCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"title"},"value":{"kind":"Variable","name":{"kind":"Name","value":"title"}}},{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateNewRootCollectionMutation, CreateNewRootCollectionMutationVariables>;
export const CreateRequestInCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateRequestInCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateTeamRequestInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createRequestInCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}},{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collection"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"team"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}}]} as unknown as DocumentNode<CreateRequestInCollectionMutation, CreateRequestInCollectionMutationVariables>;
export const CreateShortcodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateShortcode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"request"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"properties"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createShortcode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"request"},"value":{"kind":"Variable","name":{"kind":"Name","value":"request"}}},{"kind":"Argument","name":{"kind":"Name","value":"properties"},"value":{"kind":"Variable","name":{"kind":"Name","value":"properties"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"createdOn"}},{"kind":"Field","name":{"kind":"Name","value":"properties"}}]}}]}}]} as unknown as DocumentNode<CreateShortcodeMutation, CreateShortcodeMutationVariables>;
export const CreateTeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateTeam"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createTeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"members"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"role"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"photoURL"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"myRole"}},{"kind":"Field","name":{"kind":"Name","value":"ownersCount"}},{"kind":"Field","name":{"kind":"Name","value":"editorsCount"}},{"kind":"Field","name":{"kind":"Name","value":"viewersCount"}}]}}]}}]} as unknown as DocumentNode<CreateTeamMutation, CreateTeamMutationVariables>;
export const CreateTeamEnvironmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateTeamEnvironment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"variables"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createTeamEnvironment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"variables"},"value":{"kind":"Variable","name":{"kind":"Name","value":"variables"}}},{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}},{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"variables"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"teamID"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateTeamEnvironmentMutation, CreateTeamEnvironmentMutationVariables>;
export const CreateTeamInvitationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateTeamInvitation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inviteeEmail"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inviteeRole"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TeamMemberRole"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createTeamInvitation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"inviteeRole"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inviteeRole"}}},{"kind":"Argument","name":{"kind":"Name","value":"inviteeEmail"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inviteeEmail"}}},{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"teamID"}},{"kind":"Field","name":{"kind":"Name","value":"creatorUid"}},{"kind":"Field","name":{"kind":"Name","value":"inviteeEmail"}},{"kind":"Field","name":{"kind":"Name","value":"inviteeRole"}}]}}]}}]} as unknown as DocumentNode<CreateTeamInvitationMutation, CreateTeamInvitationMutationVariables>;
export const DeleteCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}]}]}}]} as unknown as DocumentNode<DeleteCollectionMutation, DeleteCollectionMutationVariables>;
export const DeleteRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"requestID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}}}]}]}}]} as unknown as DocumentNode<DeleteRequestMutation, DeleteRequestMutationVariables>;
export const DeleteShortcodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteShortcode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"code"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revokeShortcode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"code"},"value":{"kind":"Variable","name":{"kind":"Name","value":"code"}}}]}]}}]} as unknown as DocumentNode<DeleteShortcodeMutation, DeleteShortcodeMutationVariables>;
export const DeleteTeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteTeam"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteTeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<DeleteTeamMutation, DeleteTeamMutationVariables>;
export const DeleteTeamEnvironmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteTeamEnvironment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteTeamEnvironment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<DeleteTeamEnvironmentMutation, DeleteTeamEnvironmentMutationVariables>;
export const DeleteUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteUser"}}]}}]} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>;
export const DuplicateTeamCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DuplicateTeamCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"duplicateTeamCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}]}]}}]} as unknown as DocumentNode<DuplicateTeamCollectionMutation, DuplicateTeamCollectionMutationVariables>;
export const ImportFromJsonDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"importFromJSON"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"jsonString"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"importCollectionsFromJSON"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"jsonString"},"value":{"kind":"Variable","name":{"kind":"Name","value":"jsonString"}}},{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<ImportFromJsonMutation, ImportFromJsonMutationVariables>;
export const LeaveTeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LeaveTeam"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"leaveTeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<LeaveTeamMutation, LeaveTeamMutationVariables>;
export const MoveRestTeamCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"MoveRESTTeamCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"parentCollectionID"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"moveCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}},{"kind":"Argument","name":{"kind":"Name","value":"parentCollectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"parentCollectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<MoveRestTeamCollectionMutation, MoveRestTeamCollectionMutationVariables>;
export const MoveRestTeamRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"MoveRESTTeamRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"moveRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"destCollID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}},{"kind":"Argument","name":{"kind":"Name","value":"requestID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<MoveRestTeamRequestMutation, MoveRestTeamRequestMutationVariables>;
export const RemoveTeamMemberDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveTeamMember"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userUid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeTeamMember"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userUid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userUid"}}},{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>;
export const RenameCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RenameCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"newTitle"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"renameCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"newTitle"},"value":{"kind":"Variable","name":{"kind":"Name","value":"newTitle"}}},{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<RenameCollectionMutation, RenameCollectionMutationVariables>;
export const RenameTeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RenameTeam"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"newName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"renameTeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"newName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"newName"}}},{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"teamMembers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}}]}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]}}]} as unknown as DocumentNode<RenameTeamMutation, RenameTeamMutationVariables>;
export const RevokeTeamInvitationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RevokeTeamInvitation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inviteID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revokeTeamInvitation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"inviteID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inviteID"}}}]}]}}]} as unknown as DocumentNode<RevokeTeamInvitationMutation, RevokeTeamInvitationMutationVariables>;
export const UpdateCollectionOrderDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateCollectionOrder"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"destCollID"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateCollectionOrder"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}},{"kind":"Argument","name":{"kind":"Name","value":"destCollID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"destCollID"}}}]}]}}]} as unknown as DocumentNode<UpdateCollectionOrderMutation, UpdateCollectionOrderMutationVariables>;
export const UpdateEmbedPropertiesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateEmbedProperties"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"code"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"properties"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateEmbedProperties"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"code"},"value":{"kind":"Variable","name":{"kind":"Name","value":"code"}}},{"kind":"Argument","name":{"kind":"Name","value":"properties"},"value":{"kind":"Variable","name":{"kind":"Name","value":"properties"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"properties"}},{"kind":"Field","name":{"kind":"Name","value":"createdOn"}}]}}]}}]} as unknown as DocumentNode<UpdateEmbedPropertiesMutation, UpdateEmbedPropertiesMutationVariables>;
export const UpdateLookUpRequestOrderDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateLookUpRequestOrder"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextRequestID"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateLookUpRequestOrder"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"requestID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}}},{"kind":"Argument","name":{"kind":"Name","value":"nextRequestID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextRequestID"}}},{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}]}]}}]} as unknown as DocumentNode<UpdateLookUpRequestOrderMutation, UpdateLookUpRequestOrderMutationVariables>;
export const UpdateRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateTeamRequestInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}},{"kind":"Argument","name":{"kind":"Name","value":"requestID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode<UpdateRequestMutation, UpdateRequestMutationVariables>;
export const UpdateTeamCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateTeamCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"newTitle"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateTeamCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}},{"kind":"Argument","name":{"kind":"Name","value":"newTitle"},"value":{"kind":"Variable","name":{"kind":"Name","value":"newTitle"}}},{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"data"}}]}}]}}]} as unknown as DocumentNode<UpdateTeamCollectionMutation, UpdateTeamCollectionMutationVariables>;
export const UpdateTeamEnvironmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateTeamEnvironment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"variables"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateTeamEnvironment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"variables"},"value":{"kind":"Variable","name":{"kind":"Name","value":"variables"}}},{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"variables"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateTeamEnvironmentMutation, UpdateTeamEnvironmentMutationVariables>;
export const UpdateTeamMemberRoleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateTeamMemberRole"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"newRole"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TeamMemberRole"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userUid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateTeamMemberRole"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"newRole"},"value":{"kind":"Variable","name":{"kind":"Name","value":"newRole"}}},{"kind":"Argument","name":{"kind":"Name","value":"userUid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userUid"}}},{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]} as unknown as DocumentNode<UpdateTeamMemberRoleMutation, UpdateTeamMemberRoleMutationVariables>;
export const ExportAsJsonDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ExportAsJSON"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exportCollectionsToJSON"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<ExportAsJsonQuery, ExportAsJsonQueryVariables>;
export const GetCollectionChildrenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCollectionChildren"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"children"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cursor"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"data"}}]}}]}}]}}]} as unknown as DocumentNode<GetCollectionChildrenQuery, GetCollectionChildrenQueryVariables>;
export const GetCollectionChildrenIDsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCollectionChildrenIDs"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"children"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cursor"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<GetCollectionChildrenIDsQuery, GetCollectionChildrenIDsQueryVariables>;
export const GetCollectionRequestsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCollectionRequests"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requestsInCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}},{"kind":"Argument","name":{"kind":"Name","value":"cursor"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"request"}}]}}]}}]} as unknown as DocumentNode<GetCollectionRequestsQuery, GetCollectionRequestsQueryVariables>;
export const GetCollectionTitleAndDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCollectionTitleAndData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"data"}}]}}]}}]} as unknown as DocumentNode<GetCollectionTitleAndDataQuery, GetCollectionTitleAndDataQueryVariables>;
export const GetInviteDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetInviteDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inviteID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamInvitation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"inviteID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inviteID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inviteeEmail"}},{"kind":"Field","name":{"kind":"Name","value":"inviteeRole"}},{"kind":"Field","name":{"kind":"Name","value":"team"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"creator"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]}}]} as unknown as DocumentNode<GetInviteDetailsQuery, GetInviteDetailsQueryVariables>;
export const GetUserShortcodesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserShortcodes"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"myShortcodes"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cursor"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"createdOn"}},{"kind":"Field","name":{"kind":"Name","value":"properties"}}]}}]}}]} as unknown as DocumentNode<GetUserShortcodesQuery, GetUserShortcodesQueryVariables>;
export const GetMyTeamsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetMyTeams"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"myTeams"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cursor"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"myRole"}},{"kind":"Field","name":{"kind":"Name","value":"ownersCount"}},{"kind":"Field","name":{"kind":"Name","value":"teamMembers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"photoURL"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"uid"}}]}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]}}]} as unknown as DocumentNode<GetMyTeamsQuery, GetMyTeamsQueryVariables>;
export const GetSingleCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSingleCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"data"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<GetSingleCollectionQuery, GetSingleCollectionQueryVariables>;
export const GetSingleRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSingleRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"request"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"requestID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"request"}}]}}]}}]} as unknown as DocumentNode<GetSingleRequestQuery, GetSingleRequestQueryVariables>;
export const GetTeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTeam"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"team"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"teamMembers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]}}]} as unknown as DocumentNode<GetTeamQuery, GetTeamQueryVariables>;
export const GetTeamEnvironmentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTeamEnvironments"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"team"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamEnvironments"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"variables"}},{"kind":"Field","name":{"kind":"Name","value":"teamID"}}]}}]}}]}}]} as unknown as DocumentNode<GetTeamEnvironmentsQuery, GetTeamEnvironmentsQueryVariables>;
export const GetTeamMembersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTeamMembers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"team"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"members"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cursor"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]}}]} as unknown as DocumentNode<GetTeamMembersQuery, GetTeamMembersQueryVariables>;
export const GetUserInfoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"photoURL"}}]}}]}}]} as unknown as DocumentNode<GetUserInfoQuery, GetUserInfoQueryVariables>;
export const MeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"photoURL"}}]}}]}}]} as unknown as DocumentNode<MeQuery, MeQueryVariables>;
export const ResolveShortcodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ResolveShortcode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"code"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"shortcode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"code"},"value":{"kind":"Variable","name":{"kind":"Name","value":"code"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"properties"}}]}}]}}]} as unknown as DocumentNode<ResolveShortcodeQuery, ResolveShortcodeQueryVariables>;
export const RootCollectionsOfTeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RootCollectionsOfTeam"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rootCollectionsOfTeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}},{"kind":"Argument","name":{"kind":"Name","value":"cursor"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"data"}}]}}]}}]} as unknown as DocumentNode<RootCollectionsOfTeamQuery, RootCollectionsOfTeamQueryVariables>;
export const GetPendingInvitesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPendingInvites"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"team"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"teamInvitations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inviteeRole"}},{"kind":"Field","name":{"kind":"Name","value":"inviteeEmail"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<GetPendingInvitesQuery, GetPendingInvitesQueryVariables>;
export const ShortcodeCreatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ShortcodeCreated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"myShortcodesCreated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"createdOn"}},{"kind":"Field","name":{"kind":"Name","value":"properties"}}]}}]}}]} as unknown as DocumentNode<ShortcodeCreatedSubscription, ShortcodeCreatedSubscriptionVariables>;
export const ShortcodeDeletedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ShortcodeDeleted"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"myShortcodesRevoked"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<ShortcodeDeletedSubscription, ShortcodeDeletedSubscriptionVariables>;
export const ShortcodeUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ShortcodeUpdated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"myShortcodesUpdated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"createdOn"}},{"kind":"Field","name":{"kind":"Name","value":"properties"}}]}}]}}]} as unknown as DocumentNode<ShortcodeUpdatedSubscription, ShortcodeUpdatedSubscriptionVariables>;
export const TeamCollectionAddedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamCollectionAdded"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamCollectionAdded"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"data"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<TeamCollectionAddedSubscription, TeamCollectionAddedSubscriptionVariables>;
export const TeamCollectionMovedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamCollectionMoved"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamCollectionMoved"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<TeamCollectionMovedSubscription, TeamCollectionMovedSubscriptionVariables>;
export const TeamCollectionOrderUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamCollectionOrderUpdated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectionOrderUpdated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collection"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextCollection"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]} as unknown as DocumentNode<TeamCollectionOrderUpdatedSubscription, TeamCollectionOrderUpdatedSubscriptionVariables>;
export const TeamCollectionRemovedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamCollectionRemoved"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamCollectionRemoved"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<TeamCollectionRemovedSubscription, TeamCollectionRemovedSubscriptionVariables>;
export const TeamCollectionUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamCollectionUpdated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamCollectionUpdated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"data"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<TeamCollectionUpdatedSubscription, TeamCollectionUpdatedSubscriptionVariables>;
export const TeamEnvironmentCreatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamEnvironmentCreated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamEnvironmentCreated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"teamID"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"variables"}}]}}]}}]} as unknown as DocumentNode<TeamEnvironmentCreatedSubscription, TeamEnvironmentCreatedSubscriptionVariables>;
export const TeamEnvironmentDeletedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamEnvironmentDeleted"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamEnvironmentDeleted"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<TeamEnvironmentDeletedSubscription, TeamEnvironmentDeletedSubscriptionVariables>;
export const TeamEnvironmentUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamEnvironmentUpdated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamEnvironmentUpdated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"teamID"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"variables"}}]}}]}}]} as unknown as DocumentNode<TeamEnvironmentUpdatedSubscription, TeamEnvironmentUpdatedSubscriptionVariables>;
export const TeamInvitationAddedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamInvitationAdded"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamInvitationAdded"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<TeamInvitationAddedSubscription, TeamInvitationAddedSubscriptionVariables>;
export const TeamInvitationRemovedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamInvitationRemoved"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamInvitationRemoved"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<TeamInvitationRemovedSubscription, TeamInvitationRemovedSubscriptionVariables>;
export const TeamMemberAddedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamMemberAdded"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamMemberAdded"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]} as unknown as DocumentNode<TeamMemberAddedSubscription, TeamMemberAddedSubscriptionVariables>;
export const TeamMemberRemovedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamMemberRemoved"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamMemberRemoved"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<TeamMemberRemovedSubscription, TeamMemberRemovedSubscriptionVariables>;
export const TeamMemberUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamMemberUpdated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamMemberUpdated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]} as unknown as DocumentNode<TeamMemberUpdatedSubscription, TeamMemberUpdatedSubscriptionVariables>;
export const TeamRequestAddedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamRequestAdded"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamRequestAdded"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode<TeamRequestAddedSubscription, TeamRequestAddedSubscriptionVariables>;
export const TeamRequestDeletedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamRequestDeleted"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamRequestDeleted"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<TeamRequestDeletedSubscription, TeamRequestDeletedSubscriptionVariables>;
export const TeamRequestMovedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamRequestMoved"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requestMoved"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode<TeamRequestMovedSubscription, TeamRequestMovedSubscriptionVariables>;
export const TeamRequestOrderUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamRequestOrderUpdated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requestOrderUpdated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"request"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextRequest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]}}]} as unknown as DocumentNode<TeamRequestOrderUpdatedSubscription, TeamRequestOrderUpdatedSubscriptionVariables>;
export const TeamRequestUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamRequestUpdated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamRequestUpdated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode<TeamRequestUpdatedSubscription, TeamRequestUpdatedSubscriptionVariables>;
export type WithTypename<T extends { __typename?: any }> = Partial<T> & { __typename: NonNullable<T['__typename']> };

export type GraphCacheKeysConfig = {
  CollectionReorderData?: (data: WithTypename<CollectionReorderData>) => null | string,
  RequestReorderData?: (data: WithTypename<RequestReorderData>) => null | string,
  Shortcode?: (data: WithTypename<Shortcode>) => null | string,
  Team?: (data: WithTypename<Team>) => null | string,
  TeamCollection?: (data: WithTypename<TeamCollection>) => null | string,
  TeamEnvironment?: (data: WithTypename<TeamEnvironment>) => null | string,
  TeamInvitation?: (data: WithTypename<TeamInvitation>) => null | string,
  TeamMember?: (data: WithTypename<TeamMember>) => null | string,
  TeamRequest?: (data: WithTypename<TeamRequest>) => null | string,
  User?: (data: WithTypename<User>) => null | string
}

export type GraphCacheResolvers = {
  Query?: {
    collection?: GraphCacheResolver<WithTypename<Query>, QueryCollectionArgs, WithTypename<TeamCollection> | string>,
    collectionsOfTeam?: GraphCacheResolver<WithTypename<Query>, QueryCollectionsOfTeamArgs, Array<WithTypename<TeamCollection> | string>>,
    exportCollectionsToJSON?: GraphCacheResolver<WithTypename<Query>, QueryExportCollectionsToJsonArgs, Scalars['String'] | string>,
    me?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<User> | string>,
    myShortcodes?: GraphCacheResolver<WithTypename<Query>, QueryMyShortcodesArgs, Array<WithTypename<Shortcode> | string>>,
    myTeams?: GraphCacheResolver<WithTypename<Query>, QueryMyTeamsArgs, Array<WithTypename<Team> | string>>,
    request?: GraphCacheResolver<WithTypename<Query>, QueryRequestArgs, WithTypename<TeamRequest> | string>,
    requestsInCollection?: GraphCacheResolver<WithTypename<Query>, QueryRequestsInCollectionArgs, Array<WithTypename<TeamRequest> | string>>,
    rootCollectionsOfTeam?: GraphCacheResolver<WithTypename<Query>, QueryRootCollectionsOfTeamArgs, Array<WithTypename<TeamCollection> | string>>,
    searchForRequest?: GraphCacheResolver<WithTypename<Query>, QuerySearchForRequestArgs, Array<WithTypename<TeamRequest> | string>>,
    shortcode?: GraphCacheResolver<WithTypename<Query>, QueryShortcodeArgs, WithTypename<Shortcode> | string>,
    team?: GraphCacheResolver<WithTypename<Query>, QueryTeamArgs, WithTypename<Team> | string>,
    teamInvitation?: GraphCacheResolver<WithTypename<Query>, QueryTeamInvitationArgs, WithTypename<TeamInvitation> | string>,
    user?: GraphCacheResolver<WithTypename<Query>, QueryUserArgs, WithTypename<User> | string>
  },
  CollectionReorderData?: {
    collection?: GraphCacheResolver<WithTypename<CollectionReorderData>, Record<string, never>, WithTypename<TeamCollection> | string>,
    nextCollection?: GraphCacheResolver<WithTypename<CollectionReorderData>, Record<string, never>, WithTypename<TeamCollection> | string>
  },
  RequestReorderData?: {
    nextRequest?: GraphCacheResolver<WithTypename<RequestReorderData>, Record<string, never>, WithTypename<TeamRequest> | string>,
    request?: GraphCacheResolver<WithTypename<RequestReorderData>, Record<string, never>, WithTypename<TeamRequest> | string>
  },
  Shortcode?: {
    createdOn?: GraphCacheResolver<WithTypename<Shortcode>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<Shortcode>, Record<string, never>, Scalars['ID'] | string>,
    properties?: GraphCacheResolver<WithTypename<Shortcode>, Record<string, never>, Scalars['String'] | string>,
    request?: GraphCacheResolver<WithTypename<Shortcode>, Record<string, never>, Scalars['String'] | string>
  },
  Team?: {
    editorsCount?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, Scalars['Int'] | string>,
    id?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, Scalars['ID'] | string>,
    members?: GraphCacheResolver<WithTypename<Team>, TeamMembersArgs, Array<WithTypename<TeamMember> | string>>,
    myRole?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, TeamMemberRole | string>,
    name?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, Scalars['String'] | string>,
    ownersCount?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, Scalars['Int'] | string>,
    teamEnvironments?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, Array<WithTypename<TeamEnvironment> | string>>,
    teamInvitations?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, Array<WithTypename<TeamInvitation> | string>>,
    teamMembers?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, Array<WithTypename<TeamMember> | string>>,
    viewersCount?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, Scalars['Int'] | string>
  },
  TeamCollection?: {
    children?: GraphCacheResolver<WithTypename<TeamCollection>, TeamCollectionChildrenArgs, Array<WithTypename<TeamCollection> | string>>,
    data?: GraphCacheResolver<WithTypename<TeamCollection>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<TeamCollection>, Record<string, never>, Scalars['ID'] | string>,
    parent?: GraphCacheResolver<WithTypename<TeamCollection>, Record<string, never>, WithTypename<TeamCollection> | string>,
    team?: GraphCacheResolver<WithTypename<TeamCollection>, Record<string, never>, WithTypename<Team> | string>,
    title?: GraphCacheResolver<WithTypename<TeamCollection>, Record<string, never>, Scalars['String'] | string>
  },
  TeamEnvironment?: {
    id?: GraphCacheResolver<WithTypename<TeamEnvironment>, Record<string, never>, Scalars['ID'] | string>,
    name?: GraphCacheResolver<WithTypename<TeamEnvironment>, Record<string, never>, Scalars['String'] | string>,
    teamID?: GraphCacheResolver<WithTypename<TeamEnvironment>, Record<string, never>, Scalars['ID'] | string>,
    variables?: GraphCacheResolver<WithTypename<TeamEnvironment>, Record<string, never>, Scalars['String'] | string>
  },
  TeamInvitation?: {
    creator?: GraphCacheResolver<WithTypename<TeamInvitation>, Record<string, never>, WithTypename<User> | string>,
    creatorUid?: GraphCacheResolver<WithTypename<TeamInvitation>, Record<string, never>, Scalars['ID'] | string>,
    id?: GraphCacheResolver<WithTypename<TeamInvitation>, Record<string, never>, Scalars['ID'] | string>,
    inviteeEmail?: GraphCacheResolver<WithTypename<TeamInvitation>, Record<string, never>, Scalars['ID'] | string>,
    inviteeRole?: GraphCacheResolver<WithTypename<TeamInvitation>, Record<string, never>, TeamMemberRole | string>,
    team?: GraphCacheResolver<WithTypename<TeamInvitation>, Record<string, never>, WithTypename<Team> | string>,
    teamID?: GraphCacheResolver<WithTypename<TeamInvitation>, Record<string, never>, Scalars['ID'] | string>
  },
  TeamMember?: {
    membershipID?: GraphCacheResolver<WithTypename<TeamMember>, Record<string, never>, Scalars['ID'] | string>,
    role?: GraphCacheResolver<WithTypename<TeamMember>, Record<string, never>, TeamMemberRole | string>,
    user?: GraphCacheResolver<WithTypename<TeamMember>, Record<string, never>, WithTypename<User> | string>
  },
  TeamRequest?: {
    collection?: GraphCacheResolver<WithTypename<TeamRequest>, Record<string, never>, WithTypename<TeamCollection> | string>,
    collectionID?: GraphCacheResolver<WithTypename<TeamRequest>, Record<string, never>, Scalars['ID'] | string>,
    id?: GraphCacheResolver<WithTypename<TeamRequest>, Record<string, never>, Scalars['ID'] | string>,
    request?: GraphCacheResolver<WithTypename<TeamRequest>, Record<string, never>, Scalars['String'] | string>,
    team?: GraphCacheResolver<WithTypename<TeamRequest>, Record<string, never>, WithTypename<Team> | string>,
    teamID?: GraphCacheResolver<WithTypename<TeamRequest>, Record<string, never>, Scalars['ID'] | string>,
    title?: GraphCacheResolver<WithTypename<TeamRequest>, Record<string, never>, Scalars['String'] | string>
  },
  User?: {
    displayName?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    email?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    photoURL?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    uid?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['ID'] | string>
  }
};

export type GraphCacheOptimisticUpdaters = {
  acceptTeamInvitation?: GraphCacheOptimisticMutationResolver<MutationAcceptTeamInvitationArgs, WithTypename<TeamMember>>,
  addTeamMemberByEmail?: GraphCacheOptimisticMutationResolver<MutationAddTeamMemberByEmailArgs, WithTypename<TeamMember>>,
  createChildCollection?: GraphCacheOptimisticMutationResolver<MutationCreateChildCollectionArgs, WithTypename<TeamCollection>>,
  createDuplicateEnvironment?: GraphCacheOptimisticMutationResolver<MutationCreateDuplicateEnvironmentArgs, WithTypename<TeamEnvironment>>,
  createRequestInCollection?: GraphCacheOptimisticMutationResolver<MutationCreateRequestInCollectionArgs, WithTypename<TeamRequest>>,
  createRootCollection?: GraphCacheOptimisticMutationResolver<MutationCreateRootCollectionArgs, WithTypename<TeamCollection>>,
  createShortcode?: GraphCacheOptimisticMutationResolver<MutationCreateShortcodeArgs, WithTypename<Shortcode>>,
  createTeam?: GraphCacheOptimisticMutationResolver<MutationCreateTeamArgs, WithTypename<Team>>,
  createTeamEnvironment?: GraphCacheOptimisticMutationResolver<MutationCreateTeamEnvironmentArgs, WithTypename<TeamEnvironment>>,
  createTeamInvitation?: GraphCacheOptimisticMutationResolver<MutationCreateTeamInvitationArgs, WithTypename<TeamInvitation>>,
  deleteAllVariablesFromTeamEnvironment?: GraphCacheOptimisticMutationResolver<MutationDeleteAllVariablesFromTeamEnvironmentArgs, WithTypename<TeamEnvironment>>,
  deleteCollection?: GraphCacheOptimisticMutationResolver<MutationDeleteCollectionArgs, Scalars['Boolean']>,
  deleteRequest?: GraphCacheOptimisticMutationResolver<MutationDeleteRequestArgs, Scalars['Boolean']>,
  deleteTeam?: GraphCacheOptimisticMutationResolver<MutationDeleteTeamArgs, Scalars['Boolean']>,
  deleteTeamEnvironment?: GraphCacheOptimisticMutationResolver<MutationDeleteTeamEnvironmentArgs, Scalars['Boolean']>,
  deleteUser?: GraphCacheOptimisticMutationResolver<Record<string, never>, Scalars['Boolean']>,
  duplicateTeamCollection?: GraphCacheOptimisticMutationResolver<MutationDuplicateTeamCollectionArgs, Scalars['Boolean']>,
  importCollectionFromUserFirestore?: GraphCacheOptimisticMutationResolver<MutationImportCollectionFromUserFirestoreArgs, WithTypename<TeamCollection>>,
  importCollectionsFromJSON?: GraphCacheOptimisticMutationResolver<MutationImportCollectionsFromJsonArgs, Scalars['Boolean']>,
  leaveTeam?: GraphCacheOptimisticMutationResolver<MutationLeaveTeamArgs, Scalars['Boolean']>,
  moveCollection?: GraphCacheOptimisticMutationResolver<MutationMoveCollectionArgs, WithTypename<TeamCollection>>,
  moveRequest?: GraphCacheOptimisticMutationResolver<MutationMoveRequestArgs, WithTypename<TeamRequest>>,
  removeTeamMember?: GraphCacheOptimisticMutationResolver<MutationRemoveTeamMemberArgs, Scalars['Boolean']>,
  renameCollection?: GraphCacheOptimisticMutationResolver<MutationRenameCollectionArgs, WithTypename<TeamCollection>>,
  renameTeam?: GraphCacheOptimisticMutationResolver<MutationRenameTeamArgs, WithTypename<Team>>,
  replaceCollectionsWithJSON?: GraphCacheOptimisticMutationResolver<MutationReplaceCollectionsWithJsonArgs, Scalars['Boolean']>,
  revokeShortcode?: GraphCacheOptimisticMutationResolver<MutationRevokeShortcodeArgs, Scalars['Boolean']>,
  revokeTeamInvitation?: GraphCacheOptimisticMutationResolver<MutationRevokeTeamInvitationArgs, Scalars['Boolean']>,
  updateCollectionOrder?: GraphCacheOptimisticMutationResolver<MutationUpdateCollectionOrderArgs, Scalars['Boolean']>,
  updateEmbedProperties?: GraphCacheOptimisticMutationResolver<MutationUpdateEmbedPropertiesArgs, WithTypename<Shortcode>>,
  updateLookUpRequestOrder?: GraphCacheOptimisticMutationResolver<MutationUpdateLookUpRequestOrderArgs, Scalars['Boolean']>,
  updateRequest?: GraphCacheOptimisticMutationResolver<MutationUpdateRequestArgs, WithTypename<TeamRequest>>,
  updateTeamCollection?: GraphCacheOptimisticMutationResolver<MutationUpdateTeamCollectionArgs, WithTypename<TeamCollection>>,
  updateTeamEnvironment?: GraphCacheOptimisticMutationResolver<MutationUpdateTeamEnvironmentArgs, WithTypename<TeamEnvironment>>,
  updateTeamMemberRole?: GraphCacheOptimisticMutationResolver<MutationUpdateTeamMemberRoleArgs, WithTypename<TeamMember>>
};

export type GraphCacheUpdaters = {
  Query?: {
    collection?: GraphCacheUpdateResolver<{ collection: Maybe<WithTypename<TeamCollection>> }, QueryCollectionArgs>,
    collectionsOfTeam?: GraphCacheUpdateResolver<{ collectionsOfTeam: Array<WithTypename<TeamCollection>> }, QueryCollectionsOfTeamArgs>,
    exportCollectionsToJSON?: GraphCacheUpdateResolver<{ exportCollectionsToJSON: Scalars['String'] }, QueryExportCollectionsToJsonArgs>,
    me?: GraphCacheUpdateResolver<{ me: WithTypename<User> }, Record<string, never>>,
    myShortcodes?: GraphCacheUpdateResolver<{ myShortcodes: Array<WithTypename<Shortcode>> }, QueryMyShortcodesArgs>,
    myTeams?: GraphCacheUpdateResolver<{ myTeams: Array<WithTypename<Team>> }, QueryMyTeamsArgs>,
    request?: GraphCacheUpdateResolver<{ request: Maybe<WithTypename<TeamRequest>> }, QueryRequestArgs>,
    requestsInCollection?: GraphCacheUpdateResolver<{ requestsInCollection: Array<WithTypename<TeamRequest>> }, QueryRequestsInCollectionArgs>,
    rootCollectionsOfTeam?: GraphCacheUpdateResolver<{ rootCollectionsOfTeam: Array<WithTypename<TeamCollection>> }, QueryRootCollectionsOfTeamArgs>,
    searchForRequest?: GraphCacheUpdateResolver<{ searchForRequest: Array<WithTypename<TeamRequest>> }, QuerySearchForRequestArgs>,
    shortcode?: GraphCacheUpdateResolver<{ shortcode: Maybe<WithTypename<Shortcode>> }, QueryShortcodeArgs>,
    team?: GraphCacheUpdateResolver<{ team: Maybe<WithTypename<Team>> }, QueryTeamArgs>,
    teamInvitation?: GraphCacheUpdateResolver<{ teamInvitation: WithTypename<TeamInvitation> }, QueryTeamInvitationArgs>,
    user?: GraphCacheUpdateResolver<{ user: Maybe<WithTypename<User>> }, QueryUserArgs>
  },
  Mutation?: {
    acceptTeamInvitation?: GraphCacheUpdateResolver<{ acceptTeamInvitation: WithTypename<TeamMember> }, MutationAcceptTeamInvitationArgs>,
    addTeamMemberByEmail?: GraphCacheUpdateResolver<{ addTeamMemberByEmail: WithTypename<TeamMember> }, MutationAddTeamMemberByEmailArgs>,
    createChildCollection?: GraphCacheUpdateResolver<{ createChildCollection: WithTypename<TeamCollection> }, MutationCreateChildCollectionArgs>,
    createDuplicateEnvironment?: GraphCacheUpdateResolver<{ createDuplicateEnvironment: WithTypename<TeamEnvironment> }, MutationCreateDuplicateEnvironmentArgs>,
    createRequestInCollection?: GraphCacheUpdateResolver<{ createRequestInCollection: WithTypename<TeamRequest> }, MutationCreateRequestInCollectionArgs>,
    createRootCollection?: GraphCacheUpdateResolver<{ createRootCollection: WithTypename<TeamCollection> }, MutationCreateRootCollectionArgs>,
    createShortcode?: GraphCacheUpdateResolver<{ createShortcode: WithTypename<Shortcode> }, MutationCreateShortcodeArgs>,
    createTeam?: GraphCacheUpdateResolver<{ createTeam: WithTypename<Team> }, MutationCreateTeamArgs>,
    createTeamEnvironment?: GraphCacheUpdateResolver<{ createTeamEnvironment: WithTypename<TeamEnvironment> }, MutationCreateTeamEnvironmentArgs>,
    createTeamInvitation?: GraphCacheUpdateResolver<{ createTeamInvitation: WithTypename<TeamInvitation> }, MutationCreateTeamInvitationArgs>,
    deleteAllVariablesFromTeamEnvironment?: GraphCacheUpdateResolver<{ deleteAllVariablesFromTeamEnvironment: WithTypename<TeamEnvironment> }, MutationDeleteAllVariablesFromTeamEnvironmentArgs>,
    deleteCollection?: GraphCacheUpdateResolver<{ deleteCollection: Scalars['Boolean'] }, MutationDeleteCollectionArgs>,
    deleteRequest?: GraphCacheUpdateResolver<{ deleteRequest: Scalars['Boolean'] }, MutationDeleteRequestArgs>,
    deleteTeam?: GraphCacheUpdateResolver<{ deleteTeam: Scalars['Boolean'] }, MutationDeleteTeamArgs>,
    deleteTeamEnvironment?: GraphCacheUpdateResolver<{ deleteTeamEnvironment: Scalars['Boolean'] }, MutationDeleteTeamEnvironmentArgs>,
    deleteUser?: GraphCacheUpdateResolver<{ deleteUser: Scalars['Boolean'] }, Record<string, never>>,
    duplicateTeamCollection?: GraphCacheUpdateResolver<{ duplicateTeamCollection: Scalars['Boolean'] }, MutationDuplicateTeamCollectionArgs>,
    importCollectionFromUserFirestore?: GraphCacheUpdateResolver<{ importCollectionFromUserFirestore: WithTypename<TeamCollection> }, MutationImportCollectionFromUserFirestoreArgs>,
    importCollectionsFromJSON?: GraphCacheUpdateResolver<{ importCollectionsFromJSON: Scalars['Boolean'] }, MutationImportCollectionsFromJsonArgs>,
    leaveTeam?: GraphCacheUpdateResolver<{ leaveTeam: Scalars['Boolean'] }, MutationLeaveTeamArgs>,
    moveCollection?: GraphCacheUpdateResolver<{ moveCollection: WithTypename<TeamCollection> }, MutationMoveCollectionArgs>,
    moveRequest?: GraphCacheUpdateResolver<{ moveRequest: WithTypename<TeamRequest> }, MutationMoveRequestArgs>,
    removeTeamMember?: GraphCacheUpdateResolver<{ removeTeamMember: Scalars['Boolean'] }, MutationRemoveTeamMemberArgs>,
    renameCollection?: GraphCacheUpdateResolver<{ renameCollection: WithTypename<TeamCollection> }, MutationRenameCollectionArgs>,
    renameTeam?: GraphCacheUpdateResolver<{ renameTeam: WithTypename<Team> }, MutationRenameTeamArgs>,
    replaceCollectionsWithJSON?: GraphCacheUpdateResolver<{ replaceCollectionsWithJSON: Scalars['Boolean'] }, MutationReplaceCollectionsWithJsonArgs>,
    revokeShortcode?: GraphCacheUpdateResolver<{ revokeShortcode: Scalars['Boolean'] }, MutationRevokeShortcodeArgs>,
    revokeTeamInvitation?: GraphCacheUpdateResolver<{ revokeTeamInvitation: Scalars['Boolean'] }, MutationRevokeTeamInvitationArgs>,
    updateCollectionOrder?: GraphCacheUpdateResolver<{ updateCollectionOrder: Scalars['Boolean'] }, MutationUpdateCollectionOrderArgs>,
    updateEmbedProperties?: GraphCacheUpdateResolver<{ updateEmbedProperties: WithTypename<Shortcode> }, MutationUpdateEmbedPropertiesArgs>,
    updateLookUpRequestOrder?: GraphCacheUpdateResolver<{ updateLookUpRequestOrder: Scalars['Boolean'] }, MutationUpdateLookUpRequestOrderArgs>,
    updateRequest?: GraphCacheUpdateResolver<{ updateRequest: WithTypename<TeamRequest> }, MutationUpdateRequestArgs>,
    updateTeamCollection?: GraphCacheUpdateResolver<{ updateTeamCollection: WithTypename<TeamCollection> }, MutationUpdateTeamCollectionArgs>,
    updateTeamEnvironment?: GraphCacheUpdateResolver<{ updateTeamEnvironment: WithTypename<TeamEnvironment> }, MutationUpdateTeamEnvironmentArgs>,
    updateTeamMemberRole?: GraphCacheUpdateResolver<{ updateTeamMemberRole: WithTypename<TeamMember> }, MutationUpdateTeamMemberRoleArgs>
  },
  Subscription?: {
    collectionOrderUpdated?: GraphCacheUpdateResolver<{ collectionOrderUpdated: WithTypename<CollectionReorderData> }, SubscriptionCollectionOrderUpdatedArgs>,
    myShortcodesCreated?: GraphCacheUpdateResolver<{ myShortcodesCreated: WithTypename<Shortcode> }, Record<string, never>>,
    myShortcodesRevoked?: GraphCacheUpdateResolver<{ myShortcodesRevoked: WithTypename<Shortcode> }, Record<string, never>>,
    myShortcodesUpdated?: GraphCacheUpdateResolver<{ myShortcodesUpdated: WithTypename<Shortcode> }, Record<string, never>>,
    requestMoved?: GraphCacheUpdateResolver<{ requestMoved: WithTypename<TeamRequest> }, SubscriptionRequestMovedArgs>,
    requestOrderUpdated?: GraphCacheUpdateResolver<{ requestOrderUpdated: WithTypename<RequestReorderData> }, SubscriptionRequestOrderUpdatedArgs>,
    teamCollectionAdded?: GraphCacheUpdateResolver<{ teamCollectionAdded: WithTypename<TeamCollection> }, SubscriptionTeamCollectionAddedArgs>,
    teamCollectionMoved?: GraphCacheUpdateResolver<{ teamCollectionMoved: WithTypename<TeamCollection> }, SubscriptionTeamCollectionMovedArgs>,
    teamCollectionRemoved?: GraphCacheUpdateResolver<{ teamCollectionRemoved: Scalars['ID'] }, SubscriptionTeamCollectionRemovedArgs>,
    teamCollectionUpdated?: GraphCacheUpdateResolver<{ teamCollectionUpdated: WithTypename<TeamCollection> }, SubscriptionTeamCollectionUpdatedArgs>,
    teamEnvironmentCreated?: GraphCacheUpdateResolver<{ teamEnvironmentCreated: WithTypename<TeamEnvironment> }, SubscriptionTeamEnvironmentCreatedArgs>,
    teamEnvironmentDeleted?: GraphCacheUpdateResolver<{ teamEnvironmentDeleted: WithTypename<TeamEnvironment> }, SubscriptionTeamEnvironmentDeletedArgs>,
    teamEnvironmentUpdated?: GraphCacheUpdateResolver<{ teamEnvironmentUpdated: WithTypename<TeamEnvironment> }, SubscriptionTeamEnvironmentUpdatedArgs>,
    teamInvitationAdded?: GraphCacheUpdateResolver<{ teamInvitationAdded: WithTypename<TeamInvitation> }, SubscriptionTeamInvitationAddedArgs>,
    teamInvitationRemoved?: GraphCacheUpdateResolver<{ teamInvitationRemoved: Scalars['ID'] }, SubscriptionTeamInvitationRemovedArgs>,
    teamMemberAdded?: GraphCacheUpdateResolver<{ teamMemberAdded: WithTypename<TeamMember> }, SubscriptionTeamMemberAddedArgs>,
    teamMemberRemoved?: GraphCacheUpdateResolver<{ teamMemberRemoved: Scalars['ID'] }, SubscriptionTeamMemberRemovedArgs>,
    teamMemberUpdated?: GraphCacheUpdateResolver<{ teamMemberUpdated: WithTypename<TeamMember> }, SubscriptionTeamMemberUpdatedArgs>,
    teamRequestAdded?: GraphCacheUpdateResolver<{ teamRequestAdded: WithTypename<TeamRequest> }, SubscriptionTeamRequestAddedArgs>,
    teamRequestDeleted?: GraphCacheUpdateResolver<{ teamRequestDeleted: Scalars['ID'] }, SubscriptionTeamRequestDeletedArgs>,
    teamRequestUpdated?: GraphCacheUpdateResolver<{ teamRequestUpdated: WithTypename<TeamRequest> }, SubscriptionTeamRequestUpdatedArgs>,
    userDeleted?: GraphCacheUpdateResolver<{ userDeleted: WithTypename<User> }, Record<string, never>>
  },
  CollectionReorderData?: {
    collection?: GraphCacheUpdateResolver<Maybe<WithTypename<CollectionReorderData>>, Record<string, never>>,
    nextCollection?: GraphCacheUpdateResolver<Maybe<WithTypename<CollectionReorderData>>, Record<string, never>>
  },
  RequestReorderData?: {
    nextRequest?: GraphCacheUpdateResolver<Maybe<WithTypename<RequestReorderData>>, Record<string, never>>,
    request?: GraphCacheUpdateResolver<Maybe<WithTypename<RequestReorderData>>, Record<string, never>>
  },
  Shortcode?: {
    createdOn?: GraphCacheUpdateResolver<Maybe<WithTypename<Shortcode>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<Shortcode>>, Record<string, never>>,
    properties?: GraphCacheUpdateResolver<Maybe<WithTypename<Shortcode>>, Record<string, never>>,
    request?: GraphCacheUpdateResolver<Maybe<WithTypename<Shortcode>>, Record<string, never>>
  },
  Team?: {
    editorsCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Team>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<Team>>, Record<string, never>>,
    members?: GraphCacheUpdateResolver<Maybe<WithTypename<Team>>, TeamMembersArgs>,
    myRole?: GraphCacheUpdateResolver<Maybe<WithTypename<Team>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<Team>>, Record<string, never>>,
    ownersCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Team>>, Record<string, never>>,
    teamEnvironments?: GraphCacheUpdateResolver<Maybe<WithTypename<Team>>, Record<string, never>>,
    teamInvitations?: GraphCacheUpdateResolver<Maybe<WithTypename<Team>>, Record<string, never>>,
    teamMembers?: GraphCacheUpdateResolver<Maybe<WithTypename<Team>>, Record<string, never>>,
    viewersCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Team>>, Record<string, never>>
  },
  TeamCollection?: {
    children?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamCollection>>, TeamCollectionChildrenArgs>,
    data?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamCollection>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamCollection>>, Record<string, never>>,
    parent?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamCollection>>, Record<string, never>>,
    team?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamCollection>>, Record<string, never>>,
    title?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamCollection>>, Record<string, never>>
  },
  TeamEnvironment?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamEnvironment>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamEnvironment>>, Record<string, never>>,
    teamID?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamEnvironment>>, Record<string, never>>,
    variables?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamEnvironment>>, Record<string, never>>
  },
  TeamInvitation?: {
    creator?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamInvitation>>, Record<string, never>>,
    creatorUid?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamInvitation>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamInvitation>>, Record<string, never>>,
    inviteeEmail?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamInvitation>>, Record<string, never>>,
    inviteeRole?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamInvitation>>, Record<string, never>>,
    team?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamInvitation>>, Record<string, never>>,
    teamID?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamInvitation>>, Record<string, never>>
  },
  TeamMember?: {
    membershipID?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamMember>>, Record<string, never>>,
    role?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamMember>>, Record<string, never>>,
    user?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamMember>>, Record<string, never>>
  },
  TeamRequest?: {
    collection?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamRequest>>, Record<string, never>>,
    collectionID?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamRequest>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamRequest>>, Record<string, never>>,
    request?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamRequest>>, Record<string, never>>,
    team?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamRequest>>, Record<string, never>>,
    teamID?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamRequest>>, Record<string, never>>,
    title?: GraphCacheUpdateResolver<Maybe<WithTypename<TeamRequest>>, Record<string, never>>
  },
  User?: {
    displayName?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    email?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    photoURL?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    uid?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>
  },
};

export type GraphCacheConfig = Parameters<typeof offlineExchange>[0] & {
  updates?: GraphCacheUpdaters,
  keys?: GraphCacheKeysConfig,
  optimistic?: GraphCacheOptimisticUpdaters,
  resolvers?: GraphCacheResolvers,
};