import { ExperimentsPlatformDef } from "@hoppscotch/common/platform/experiments"
import { def as platformAuth } from "./firebase/auth"
import * as E from "fp-ts/Either"
import { z } from "zod"

const GenerateRequestNameResponseSchema = z.object({
  request_name: z.string(),
})

const generateRequestName = async (requestInfo: string) => {
  try {
    const res = await fetch(
      `${
        import.meta.env.VITE_BACKEND_API_URL
      }/ai-experiments/generate-request-name`,
      {
        method: "POST",
        body: JSON.stringify({
          request_info: requestInfo,
        }),
        headers: {
          ...platformAuth.getBackendHeaders(),
          "Content-Type": "application/json",
        },
      }
    )

    const data = await res.json()

    const result = GenerateRequestNameResponseSchema.safeParse(data)

    if (!result.success) {
      return E.left("UNABLE_TO_PARSE_RESPONSE")
    }

    return E.right(result.data.request_name)
  } catch (e) {
    return E.left("CANNOT_GENERATE_REQUEST_NAME")
  }
}

export const def: ExperimentsPlatformDef = {
  aiExperiments: {
    enableAIExperiments: true,
    generateRequestName,
  },
}
