import { createHoppApp } from "@hoppscotch/common"
import { initializeFirebase } from "./firebase/init"
import { def as authDef } from "./firebase/auth"
import { def as envDef } from "./environments"
import { def as collectionsDef } from "./collections"
import { def as settingsDef } from "./settings"
import { def as historyDef } from "./history"
import { def as experimentsDef } from "./experiments.platform"
import { addPosthogIgnoreClass, def as analyticsDef } from "./analytics"
import { browserInterceptor } from "@hoppscotch/common/platform/std/interceptors/browser"
import { proxyInterceptor } from "@hoppscotch/common/platform/std/interceptors/proxy"
import { ExtensionInspectorService } from "@hoppscotch/common/platform/std/inspections/extension.inspector"
import { ExtensionInterceptorService } from "@hoppscotch/common/platform/std/interceptors/extension"
import { stdFooterItems } from "@hoppscotch/common/platform/std/ui/footerItem"
import { stdSupportOptionItems } from "@hoppscotch/common/platform/std/ui/supportOptionsItem"
import {
  chatWithUsFooterMenuItem,
  chatWithUsSupportOptionMenuItem,
  crispModule,
} from "./crisp"
import { browserIODef } from "@hoppscotch/common/platform/std/io"
import { sentryModule } from "./sentry"
import Newsletter from "./components/Newsletter.vue"
import { DocumentationSearcherService } from "./services/spotlight/searchers/documentation.searcher"
import { ref } from "vue"

initializeFirebase()

createHoppApp("#app", {
  ui: {
    additionalFooterMenuItems: [chatWithUsFooterMenuItem, ...stdFooterItems],
    additionalSupportOptionsMenuItems: [
      chatWithUsSupportOptionMenuItem,
      ...stdSupportOptionItems,
    ],
    onCodemirrorInstanceMount: addPosthogIgnoreClass,
    additionalProfileSections: [Newsletter],
  },
  addedHoppModules: [crispModule, sentryModule],
  auth: authDef,
  analytics: analyticsDef,
  io: browserIODef,
  sync: {
    environments: envDef,
    collections: collectionsDef,
    settings: settingsDef,
    history: historyDef,
  },
  interceptors: {
    default: "proxy",
    interceptors: [
      { type: "standalone", interceptor: browserInterceptor },
      { type: "standalone", interceptor: proxyInterceptor },
      { type: "service", service: ExtensionInterceptorService },
    ],
  },
  additionalInspectors: [
    { type: "service", service: ExtensionInspectorService },
  ],
  spotlight: {
    additionalSearchers: [DocumentationSearcherService],
  },
  platformFeatureFlags: {
    exportAsGIST: true,
    hasTelemetry: true,
    workspaceSwitcherLogin: ref(false),
  },
  experiments: experimentsDef,
})
